jQuery(document).ready(function($){
	//Throttle function - prevents too many calls in a certain timeframe

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;

	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	//Shrinks the nav if not at the top of the page.
	$(window).scroll(throttle(function(event){
		if(window.scrollY > 0){
			$('.site-header').addClass('scrolling');
		}else{
			$('.site-header').removeClass('scrolling');
		}
	}, 250))

	if(window.scrollY > 0){
		$('.site-header').addClass('scrolling');
	}else{
		$('.site-header').removeClass('scrolling');
	}


	$mainLinks = $('.site-header .menu-primary-nav-container>ul');
	$mainLinksWithChildren = $('.site-header .menu-primary-nav-container>ul>li.menu-item-has-children')
	$mainLinksWithChildrenLinks = $('.site-header .menu-primary-nav-container>ul>li.menu-item-has-children>a')
	$svgForDropdownLinksString = '<svg class="mobile-svg d-block d-lg-none" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4141 8.9375H0V7.0625H11.4141L6.16406 1.8125L7.5 0.5L15 8L7.5 15.5L6.16406 14.1875L11.4141 8.9375Z" fill="black"/></svg>';
	$svgForMenuLinksString = '<svg class="desktop-svg d-none d-lg-block" class="d-none d-lg-block" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9.77271L0 1.77275L1.49113 0.281616L8 6.79048L14.5089 0.281616L16 1.77275L8 9.77271Z" fill="#005299"/></svg>';

	function closeNav(){
		$mainLinksWithChildren.each(function(){
				if(window.innerWidth < 1350){
					$('.site-header .menu-primary-nav-container').removeClass('slid');
					$(this).removeClass('menu-active');
				}else{
					$(this).removeClass('menu-active');
				}
				
			})
			$dropdownLinks.each(function(){
				$(this).parent().removeClass('menu-active');
			})
			$mainLinks.removeClass('submenu-active');
			$('.site-header').removeClass('menu-open');
	}


	//Adjusts structure of dropdown nav
	$mainLinksWithChildren.each(function(){
		$title = "<p class='submenu-title'><a href='" + $(this).children('a').eq(0).attr('href') + "'>" + $(this).children('a').eq(0).text() + "</a></p>";
		if($(this).children('a').attr('href') == "#" || $(this).children('a').attr('href') == ""){
			$title = "<p class='submenu-title'></p>";
		}
		$closeButtonIcon = '<svg class="d-none d-lg-block close-button-icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.34805 18.5L0.59375 17.7623L9.0419 9.5L0.59375 1.2377L1.34805 0.5L9.7962 8.76229L18.2444 0.5L18.9987 1.2377L10.5505 9.5L18.9987 17.7623L18.2444 18.5L9.7962 10.2377L1.34805 18.5Z" fill="#1C1B1F"/></svg>'
		$closebuttonContent = '<div class="d-lg-none mobile-content"><strong>Main Menu</strong></div>'
		$(this).append("<div class='dropdown-wrapper'><div class='container'><div class='row title-row'><div class='col-12'></div></div><div class='row menu-row'></div></div>");
		$col1 = false;
		$col2 = false;
		$col3 = false;
		$col4 = false;
		$($(this).children('.dropdown-menu').children()).each(function(){
			switch(true){
				case this.classList.contains('column-order-1'):
					if($col1 === false){
						$(this).parent().parent().find('.dropdown-wrapper .menu-row').append("<div class='col-lg-3 menu-col-1'><ul></ul></div>");
						$col1 = true;
					}
					$(this).parent().parent().find('.dropdown-wrapper .menu-row>.menu-col-1>ul').append(this);
					break;
				case this.classList.contains('column-order-2'):
					if($col2 === false){
						$(this).parent().parent().find('.dropdown-wrapper .menu-row').append("<div class='col-lg-3 menu-col-2'><ul></ul></div>");
						$col2 = true;
					}
					$(this).parent().parent().find('.dropdown-wrapper .menu-row>.menu-col-2>ul').append(this);
					break;
				case this.classList.contains('column-order-3'):
					if($col3 === false){
						$(this).parent().parent().find('.dropdown-wrapper .menu-row').append("<div class='col-lg-3 menu-col-3'><ul></ul></div>");
						$col3 = true;
					}
					$(this).parent().parent().find('.dropdown-wrapper .menu-row>.menu-col-3>ul').append(this);
					break;
				case this.classList.contains('column-order-4'):
					if($col4 === false){
						$(this).parent().parent().find('.dropdown-wrapper .menu-row').append("<div class='col-lg-3 menu-col-4'><ul></ul></div>");
						$col4 = true;
					}
					$(this).parent().parent().find('.dropdown-wrapper .menu-row>.menu-col-4>ul').append(this);
					break;
			}
		})
		// $(this).find('.dropdown-wrapper .menu-row>div').prepend($(this).children('.dropdown-menu'));
		$(this).find('.dropdown-wrapper .title-row>div').prepend($title);
		$(this).find('.dropdown-wrapper .title-row>div').append('<button class="close-button flex-row"></button>');
		$(this).find('.close-button').append($svgForDropdownLinksString);
		$(this).find('.close-button').append($closebuttonContent);
		$(this).find('.close-button').append($closeButtonIcon);

		//Close button controls
		$(this).find('.close-button').click(function(event){
			event.stopPropagation();
			closeNav();
		})

	})

	//Close on hover
	$('.site-header').on('mouseleave', function(){
		closeNav();
	})


	//Open dropdown menu
	$mainLinksWithChildrenLinks.each(function(){
		$(this).append($svgForDropdownLinksString);
		$(this).on('click mouseover', function(event){
			console.log(event.type);
			event.preventDefault();
			if(window.innerWidth < 700){
				if(event.type != "mouseover"){
					$('.site-header .menu-primary-nav-container').addClass('slid');
					$mainLinksWithChildren.each(function(){
						$(this).removeClass('menu-active')
					})
					$dropdownLinks.each(function(){
						$(this).parent().removeClass('menu-active');
					})
					$(this).parent().addClass('menu-active');
					$mainLinks.addClass('submenu-active');
				}
			}else{
				
					$mainLinksWithChildren.each(function(){
						$(this).removeClass('menu-active')
					})
					$dropdownLinks.each(function(){
						$(this).parent().removeClass('menu-active');
					})
					$(this).parent().addClass('menu-active');
					$('.site-header').addClass('menu-open');
					$mainLinks.addClass('submenu-active');
				
			}
			
			
		})
	})

	//Open next level of dropdown menu
	$dropdownLinks = $('.site-header .menu-primary-nav-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children>a');
	// $dropdownLinks = $('.site-header .menu-main-menu-container>ul>li.menu-item-has-children>.dropdown-wrapper');
	// $dropdownLinks.each(function(){
	// 	$(this).on('click', function(event){
	// 		if(window.innerWidth >= 1350){
	// 			event.preventDefault();
	// 			$dropdownLinks.each(function(){
	// 				$(this).parent().removeClass('menu-active');
	// 			})
	// 			$(this).parent().addClass('menu-active');
	// 		}
	// 	})
	// })

	//Affect all links
	$allDropdownLinks = $('.site-header .menu-primary-nav-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li a');
	$allDropdownLinks.each(function(){
		$(this).on('click', function(){
			if(window.innerWidth < 900){
				$('.navbar-toggler').click();
			}
			closeNav();
		})
	})

	$mainLinksWithChildrenLinks.each(function(){
		$(this).append($svgForMenuLinksString)
	})

	//Adjust structure of second level of dropdown
	// $dropdownMenuItems = $('.site-header .menu-primary-nav-container>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children');
	// $dropdownMenuItems.each(function(){
	// 	$linkTitle = $(this).children('a').text();
	// 	$linkURL = $(this).children('a').attr('href');
	// 	$listItem = "<li class='parent-link current-page-parent menu-active' itemscope='itemscope' itemtype='https://www.schema.org/SiteNavigationElement'> <a href='" + $linkURL + "' class='dropdown-item'>View " + $linkTitle + $svgForDropdownLinksString + "</a> </li>"
	// 	$wrapper = "<div class='sub-dropdown-wrapper'></div>"
	// 	$(this).append($wrapper);
	// 	$(this).children('.sub-dropdown-wrapper').append($listItem);
	// 	$(this).children('.sub-dropdown-wrapper').append($(this).children('.dropdown-menu'));
	// })



});